import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

import { HeadingPaths } from "./index"
import { Icon, IconProps } from "@chakra-ui/react"

const fill = keyframes`
  from { fill: transparent; }
  to { fill: #352d87; }
`

const line = keyframes`
  to { stroke-dashoffset: 0; }
`

const Path = styled.path<{ length: number; delay: number }>`
  stroke-dasharray: ${(props) => props.length};
  stroke-dashoffset: ${(props) => props.length};
  animation: ${line} 2s ease forwards;
  animation-delay: ${(props) => `${props.delay}s`};
`

interface LogoTitleProps extends IconProps {
  animate?: boolean
}

export const LogoTitle = ({ animate = false, ...rest }: LogoTitleProps) => {
  return (
    <Icon
      width="219"
      height="55"
      viewBox="0 0 219 55"
      color={animate ? "#352d87" : "white"}
      fill={animate ? "none" : "white"}
      willChange="auto"
      animation={animate ? `${fill} 0.4s ease forwards 4s` : "none"}
      {...rest}
    >
      {HeadingPaths.map((path, index) => (
        <Path
          key={index}
          stroke={animate ? "currentColor" : "inherit"}
          strokeWidth="0.7"
          d={path.d}
          length={path.length}
          delay={index * 0.3 + 0.3}
          onAnimationEnd={(e) => e.stopPropagation()}
        />
      ))}
    </Icon>
  )
}
