export const HeadingPaths = [
  {
    d: "M1.06339 53.272L19.7453 11.2011L19.8046 11.0675L19.7513 10.9314L18.3049 7.23769L28.1062 6.02513L47.5676 53.271H36.5965L31.4244 40.229L31.3368 40.008H31.0991H14.0989H13.8537L13.77 40.2385L11.1396 47.4785L11.1206 47.5307L11.1187 47.5863L10.9278 53.272H1.06339ZM22.5173 17.8989L22.1766 17.048L21.8634 17.9094L15.8601 34.4204L15.6894 34.89H16.189H28.8016H29.3187L29.1266 34.4099L22.5173 17.8989Z",
    length: 230,
  },
  {
    d: "M73.1906 25.1178L72.9384 19.665H82.7762L68.8327 53.272H58.7182L46.0941 20.1716L56.4779 19.1487L65.6972 46.8974L66.0384 47.9244L66.3632 46.892L73.1748 25.239L73.1935 25.1798L73.1906 25.1178Z",
    length: 162,
  },
  {
    d: "M89.1107 26.826V26.7701L89.0932 26.7169L87.3584 21.4269L98.7976 19.0659V53.272H89.1107V26.826ZM93.8858 14.701C92.1402 14.701 90.7815 14.151 89.8618 13.2754C88.9424 12.4001 88.4359 11.1754 88.4359 9.774C88.4359 8.37311 88.9419 7.1661 89.8596 6.30773C90.7783 5.44828 92.1374 4.913 93.8858 4.913C95.6341 4.913 96.9752 5.44785 97.8766 6.30534C98.7773 7.16219 99.269 8.36928 99.269 9.774C99.269 11.1793 98.7771 12.4041 97.8747 13.2778C96.9725 14.1514 95.6318 14.701 93.8858 14.701Z",
    length: 121,
  },
  {
    d: "M121.892 39.1444L121.656 38.8039L121.36 39.0941L118.46 41.936L118.355 42.039V42.186V53.272H108.672V8.69099V8.63231L108.653 8.57684L106.853 3.36004L118.359 0.997768V37.246V38.3614L118.997 37.4459L127.899 24.6569L127.937 24.6023L127.953 24.5374L129.103 19.665H141.329L128.312 32.3311L128.098 32.5397L128.27 32.784L142.752 53.271H131.703L121.892 39.1444Z",
    length: 232,
  },
  {
    d: "M172.269 48.8819L172.268 48.1281L171.693 48.6147C167.881 51.8357 163.305 54.284 158.363 54.284C155.319 54.284 152.666 53.4881 150.799 51.4214L150.794 51.415L150.788 51.4089C148.923 49.5384 148.125 47.0078 148.125 43.198V26.688V26.6328L148.108 26.5802L146.439 21.4237L157.811 19.0632V42.3023C157.736 43.145 157.829 43.9943 158.085 44.8006C158.342 45.6121 158.759 46.3637 159.311 47.011L159.328 47.0319L159.349 47.0498C160.558 48.0858 162.125 48.6023 163.711 48.4875C166.401 48.3853 169.041 47.7267 171.465 46.553L171.663 46.4574V46.238V20.562L181.349 19.0622V46.517V46.5779L181.37 46.6352L183.17 51.651L172.269 54.1392L172.269 48.8819Z",
    length: 194,
  },
  {
    d: "M201.659 23.832L201.656 23.832C200.119 23.8564 198.586 24.0172 197.076 24.3125L196.88 24.3509L196.814 24.5397C196.532 25.3375 196.389 26.1776 196.388 27.0238V27.024C196.388 28.1164 196.703 29.0529 197.582 29.8023C198.437 30.5308 199.792 31.0521 201.8 31.428C201.8 31.428 201.8 31.428 201.8 31.428L206.79 32.3749L206.792 32.3753C211.046 33.1485 214.008 34.4005 215.904 36.0982C217.785 37.7827 218.646 39.9328 218.646 42.587H218.646L218.646 42.5968C218.683 43.933 218.455 45.2632 217.973 46.5096C217.493 47.7543 216.77 48.8905 215.847 49.8517C212.967 52.6719 208.217 54.2819 202.067 54.282C198.319 54.2647 194.587 53.7855 190.956 52.8556L188.587 43.7876L188.681 43.7404C193.463 47.2487 199.242 49.1268 205.17 49.095C207.003 49.1352 208.832 48.9026 210.597 48.4049L210.769 48.3562L210.831 48.1878C211.105 47.4342 211.247 46.6387 211.251 45.8365V45.835C211.251 44.7428 210.953 43.8186 210.017 43.059C209.114 42.3262 207.644 41.7705 205.375 41.2925L205.373 41.2922L200.113 40.2092L200.113 40.2092L200.109 40.2085C196.501 39.5046 193.721 38.3054 191.848 36.5971C189.985 34.8992 188.995 32.6775 188.995 29.861H188.995L188.995 29.8527C188.963 28.5328 189.201 27.2205 189.694 25.9962C190.186 24.772 190.923 23.6617 191.858 22.7334L191.858 22.7334L191.86 22.7321C194.202 20.3819 198.35 18.6352 204.56 18.635C207.7 18.6545 210.825 19.0658 213.864 19.8594L216.426 27.9075L216.352 27.9607C211.944 25.217 206.849 23.7844 201.659 23.832Z",
    length: 193,
  },
]

export const SubHeadingPaths = [
  {
    d: "M5.57657 8.06159L7.89178 0.976004H9.76101V9.85899H8.60599V2.92299V2.77299H8.45599H8.42999H8.32277L8.28807 2.87444L5.89879 9.85899H4.85423L2.46492 2.87444L2.43022 2.77299H2.323H2.29999H2.14999V2.92299V9.85899H0.992011V0.976004H2.88712L5.26579 8.06273L5.30011 8.165H5.40799H5.43399H5.54278L5.57657 8.06159Z",
    length: 70,
  },
  {
    d: "M16.6241 7.53098L16.5896 7.42899H16.482H12.996H12.8883L12.8539 7.53103L12.0683 9.85899H10.8124L14.0549 0.976004H15.4712L18.7282 9.85899H17.4107L16.6241 7.53098ZM13.2697 6.31058L13.2039 6.50799H13.412H16.076H16.2838L16.2184 6.31075L14.9074 2.35976L14.8733 2.257H14.765H14.728H14.6199L14.5857 2.3596L13.2697 6.31058Z",
    length: 45,
  },
  {
    d: "M19.7881 0.976004H23.814C24.7458 0.976004 25.4273 1.20221 25.8744 1.59593C26.3184 1.9871 26.552 2.56215 26.552 3.30699V3.31484L26.5528 3.32264C26.6009 3.78113 26.4946 4.24257 26.2507 4.63376C26.0068 5.02495 25.6392 5.32353 25.2064 5.48216L25.108 5.51822V5.623V5.64799V5.77756L25.2362 5.7964C25.4717 5.83101 25.7611 5.94067 25.992 6.20266C26.2211 6.46268 26.4068 6.88896 26.408 7.58421C26.3373 8.36461 26.4716 9.14854 26.7957 9.85899H25.494C25.3382 9.48446 25.2753 9.07729 25.3114 8.67232L25.312 8.67237V8.65901C25.312 7.81632 25.2318 7.16702 24.9469 6.72897C24.6497 6.27208 24.1548 6.077 23.425 6.077H21.172H21.022V6.22701V9.85899H19.7919L19.7881 0.976004ZM21.018 5.00504L21.0181 5.15499H21.168H23.6661C23.8854 5.18333 24.1082 5.16196 24.3182 5.09238C24.5315 5.02171 24.7258 4.90319 24.8862 4.74588C25.0466 4.58857 25.1689 4.39664 25.2438 4.18481C25.3176 3.97575 25.3433 3.75274 25.319 3.53241C25.3172 2.91925 25.1253 2.51936 24.7974 2.28062C24.4782 2.04815 24.059 1.99101 23.653 1.99101H21.167H21.0169L21.017 2.14106L21.018 5.00504Z",
    length: 53,
  },
  {
    d: "M28.27 0.976004H29.5V9.85899H28.27V0.976004Z",
    length: 21,
  },
  {
    d: "M33.322 2.06599H30.706V0.976004H37.481V2.06599H34.851H34.701V2.21599V9.85899H33.472V2.21599V2.06599H33.322Z",
    length: 32,
  },
  {
    d: "M38.661 0.976004H39.891V9.85899H38.661V0.976004Z",
    length: 21,
  },
  {
    d: "M46.4866 8.06161L48.8028 0.976004H50.672V9.85899H49.516V2.92299V2.77299H49.366H49.34H49.2328L49.1981 2.87444L46.8088 9.85899H45.7652L43.3759 2.87444L43.3412 2.77299H43.234H43.211H43.061V2.92299V9.85899H41.902V0.976004H43.7981L46.1758 8.06271L46.2101 8.165H46.318H46.344H46.4528L46.4866 8.06161Z",
    length: 70,
  },
  {
    d: "M54.051 8.76501H58.746V9.85499H52.672V0.976004H58.661V2.06599H54.051H53.901V2.21599V4.61601V4.76601H54.051H58.308V5.779H54.051H53.901V5.929V8.61501V8.76501H54.051Z",
    length: 49,
  },
  {
    d: "M68.3781 7.53103L68.3437 7.42899H68.236H64.747H64.6393L64.6049 7.53103L63.8193 9.85899H62.5635L65.8089 0.976004H67.2252L70.4822 9.85899H69.1637L68.3781 7.53103ZM65.0208 6.31033L64.9545 6.50799H65.163H67.832H68.0398L67.9744 6.31075L66.6634 2.35976L66.6293 2.257H66.521H66.487H66.3791L66.3448 2.35934L65.0208 6.31033Z",
    length: 45,
  },
  {
    d: "M71.445 0.976004H72.675V6.175C72.675 6.78721 72.6909 7.49062 72.9767 8.03976C73.1223 8.31949 73.3379 8.55991 73.6527 8.72876C73.9658 8.89665 74.3681 8.989 74.881 8.989C75.394 8.989 75.7963 8.89665 76.1094 8.72877C76.4244 8.55993 76.64 8.31953 76.7858 8.03981C77.0719 7.49066 77.088 6.78726 77.088 6.175V0.976004H78.317V6.70201C78.317 7.61329 77.955 8.48724 77.3106 9.13162C76.6662 9.776 75.7923 10.138 74.881 10.138C73.9697 10.138 73.0957 9.776 72.4514 9.13162C71.807 8.48725 71.445 7.6133 71.445 6.70201V0.976004Z",
    length: 44,
  },
  {
    d: "M82.028 2.06599H79.412V0.976004H86.184V2.06599H83.557H83.407V2.21599V9.85899H82.178V2.21599V2.06599H82.028Z",
    length: 32,
  },
  {
    d: "M88.126 5.41798V5.56798H88.1319C88.1529 6.41848 88.3545 7.26314 88.7804 7.91146C89.2356 8.60453 89.9451 9.06798 90.937 9.06798C91.9288 9.06798 92.6379 8.60507 93.0927 7.91217C93.5431 7.22594 93.742 6.31941 93.742 5.41798C93.742 4.51646 93.5423 3.60993 93.0915 2.92375C92.6364 2.23095 91.9274 1.76798 90.937 1.76798C89.9467 1.76798 89.2362 2.23116 88.7796 2.92394C88.3273 3.6102 88.126 4.51674 88.126 5.41798ZM94.971 5.41798C94.971 6.70089 94.588 7.86824 93.8949 8.71189C93.204 9.55282 92.2002 10.079 90.937 10.079C89.6738 10.079 88.6692 9.55281 87.9776 8.71183C87.2838 7.86816 86.9 6.70082 86.9 5.41798C86.9 4.13511 87.2833 2.96778 87.9769 2.12413C88.6682 1.2832 89.6728 0.75701 90.937 0.75701C92.2012 0.75701 93.205 1.28295 93.8956 2.1237C94.5884 2.9672 94.971 4.13453 94.971 5.41798Z",
    length: 49,
  },
  {
    d: "M102.099 7.59099V0.976004H103.257V9.85899H101.878L97.9422 3.17985L97.8987 3.106H97.813H97.776H97.626V3.256V9.85899H96.47V0.976004H97.8493L101.797 7.66721L101.84 7.74099H101.926H101.949H102.099V7.59099Z",
    length: 53,
  },
  {
    d: "M112.839 5.41798C112.839 6.70086 112.456 7.86821 111.762 8.71188C111.071 9.55283 110.068 10.079 108.805 10.079C107.542 10.079 106.537 9.55281 105.845 8.7118C105.151 7.86811 104.767 6.70077 104.767 5.41798C104.767 4.13511 105.15 2.96778 105.844 2.12414C106.535 1.28322 107.54 0.75701 108.805 0.75701C110.07 0.75701 111.074 1.28296 111.764 2.1237C112.457 2.96718 112.839 4.1345 112.839 5.41798ZM105.997 5.41798C105.997 6.3185 106.196 7.22503 106.648 7.91148C107.103 8.60456 107.813 9.06798 108.805 9.06798C109.797 9.06798 110.506 8.60507 110.961 7.91212C111.411 7.22588 111.609 6.31934 111.609 5.41798C111.609 4.51649 111.41 3.60997 110.959 2.92378C110.504 2.23096 109.795 1.76798 108.805 1.76798C107.815 1.76798 107.105 2.2312 106.649 2.92404C106.198 3.61032 105.997 4.51684 105.997 5.41798Z",
    length: 49,
  },
  {
    d: "M118.954 8.06161L121.27 0.976004H123.142V9.85899H121.983V2.92299V2.77299H121.833H121.811H121.704L121.669 2.87444L119.28 9.85899H118.232L115.846 2.87449L115.811 2.77299H115.704H115.676H115.526V2.92299V9.85899H114.371L114.375 0.976004H116.268L118.644 8.06268L118.678 8.165H118.786H118.811H118.92L118.954 8.06161Z",
    length: 70,
  },
  {
    d: "M132.748 5.41798C132.748 6.70089 132.365 7.86823 131.672 8.71189C130.981 9.55282 129.977 10.079 128.714 10.079C127.451 10.079 126.446 9.55281 125.755 8.71183C125.061 7.86816 124.677 6.70082 124.677 5.41798C124.677 4.1351 125.06 2.96778 125.754 2.12413C126.445 1.2832 127.45 0.75701 128.714 0.75701C129.978 0.75701 130.982 1.28295 131.673 2.12371C132.365 2.9672 132.748 4.13453 132.748 5.41798ZM125.907 5.41798C125.907 6.3185 126.106 7.22502 126.557 7.91146C127.013 8.60453 127.722 9.06798 128.714 9.06798C129.706 9.06798 130.415 8.60507 130.87 7.91217C131.32 7.22594 131.519 6.31941 131.519 5.41798C131.519 4.51646 131.319 3.60993 130.868 2.92375C130.413 2.23095 129.704 1.76798 128.714 1.76798C127.724 1.76798 127.014 2.23121 126.559 2.92407C126.107 3.61035 125.907 4.51688 125.907 5.41798Z",
    length: 49,
  },
  {
    d: "M134.21 0.976004H135.44V6.175C135.44 6.78721 135.456 7.49062 135.742 8.03976C135.887 8.31949 136.103 8.55991 136.418 8.72876C136.731 8.89665 137.133 8.989 137.646 8.989C138.159 8.989 138.561 8.89665 138.874 8.72877C139.189 8.55993 139.405 8.31953 139.551 8.03981C139.837 7.49067 139.853 6.78727 139.853 6.175V0.976004H141.082V6.70201C141.082 7.6133 140.72 8.48725 140.076 9.13162C139.431 9.776 138.557 10.138 137.646 10.138C136.735 10.138 135.861 9.776 135.216 9.13162C134.572 8.48724 134.21 7.61329 134.21 6.70201V0.976004Z",
    length: 44,
  },
  {
    d: "M144.639 5.49293L144.63 5.49039L144.621 5.48893C144.107 5.40586 143.641 5.13935 143.309 4.73883C142.977 4.33832 142.801 3.83098 142.815 3.31087L142.815 3.31087V3.30699C142.815 2.43871 143.194 1.80737 143.759 1.38864C144.329 0.966382 145.094 0.75701 145.858 0.75701C146.743 0.75701 147.546 1.00408 148.124 1.47174C148.666 1.91068 149.02 2.55083 149.058 3.391H147.819C147.757 2.83862 147.541 2.43491 147.198 2.1678C146.832 1.88228 146.342 1.77 145.799 1.77C145.428 1.77 144.998 1.83994 144.655 2.04252C144.304 2.25037 144.049 2.5968 144.049 3.12001L144.049 3.12219C144.054 3.44632 144.174 3.75814 144.388 4.0016C144.6 4.24234 144.889 4.40063 145.206 4.44885C145.267 4.46656 145.476 4.5242 145.75 4.59977C145.926 4.64853 146.13 4.70476 146.339 4.76254C146.897 4.91708 147.491 5.08305 147.715 5.15225C148.181 5.29772 148.589 5.59001 148.876 5.98562C149.163 6.38127 149.315 6.85914 149.309 7.3481L149.309 7.3481V7.34999C149.309 8.33054 148.898 9.0059 148.286 9.44137C147.667 9.88144 146.832 10.083 145.984 10.083C145.01 10.083 144.144 9.83856 143.521 9.33138C142.93 8.85115 142.542 8.12309 142.493 7.1006L143.718 7.09739C143.751 7.7665 144.008 8.25626 144.42 8.5804C144.856 8.92371 145.447 9.066 146.078 9.066C146.777 9.066 147.278 8.88426 147.607 8.59167C147.935 8.29999 148.074 7.91233 148.076 7.53216C148.101 7.25954 148.034 6.98635 147.887 6.75541C147.739 6.52328 147.518 6.34709 147.259 6.25463C146.944 6.12909 146.586 6.02495 146.162 5.90986C146.047 5.8787 145.927 5.8467 145.801 5.81321C145.46 5.72207 145.077 5.61983 144.639 5.49293Z",
    length: 49,
  },
  {
    d: "M155.299 6.48602V9.859H154.07L154.072 0.976014H157.776V0.976173L157.783 0.975855C158.145 0.959166 158.507 1.01612 158.847 1.14328C159.187 1.27045 159.497 1.46516 159.759 1.71568C160.022 1.96619 160.23 2.26732 160.373 2.60083C160.516 2.93432 160.589 3.29326 160.589 3.656C160.589 4.01874 160.516 4.37771 160.373 4.7112C160.23 5.04471 160.022 5.3458 159.759 5.59632C159.497 5.84684 159.187 6.04159 158.847 6.16875C158.507 6.29591 158.145 6.35286 157.783 6.33618L157.783 6.33602H157.776H155.449H155.299V6.48602ZM155.301 5.173V5.323H155.451H157.687C157.911 5.33974 158.135 5.30845 158.346 5.23121C158.558 5.15328 158.752 5.03041 158.912 4.87097C159.073 4.71153 159.197 4.51929 159.276 4.30743C159.354 4.1008 159.387 3.88022 159.374 3.66003C159.405 3.43657 159.385 3.20881 159.315 2.99415C159.244 2.77415 159.121 2.57401 158.958 2.40979C158.795 2.24557 158.596 2.12184 158.377 2.04852C158.161 1.97637 157.931 1.955 157.705 1.98601H155.451H155.301V2.13601V5.173Z",
    length: 43,
  },
  {
    d: "M162.101 0.976004H163.331V9.85899H162.101V0.976004Z",
    length: 21,
  },
  {
    d: "M172.975 5.41798C172.975 6.70089 172.592 7.86822 171.898 8.71186C171.207 9.55278 170.203 10.079 168.938 10.079C167.673 10.079 166.67 9.5528 165.979 8.71192C165.286 7.8683 164.904 6.70095 164.904 5.41798C164.904 4.1351 165.287 2.96776 165.981 2.1241C166.672 1.28316 167.675 0.75701 168.938 0.75701C170.201 0.75701 171.205 1.28294 171.897 2.12379C172.591 2.96734 172.975 4.13466 172.975 5.41798ZM166.133 5.41798C166.133 6.31854 166.333 7.22504 166.783 7.91144C167.239 8.60445 167.948 9.06798 168.938 9.06798C169.928 9.06798 170.637 8.60502 171.092 7.91222C171.543 7.22604 171.743 6.31951 171.743 5.41798C171.743 4.51649 171.544 3.60996 171.093 2.92377C170.638 2.23093 169.929 1.76798 168.938 1.76798C167.947 1.76798 167.238 2.23122 166.783 2.92416C166.332 3.61046 166.133 4.51698 166.133 5.41798Z",
    length: 49,
  },
  {
    d: "M180.102 7.59099V0.976004H181.258V9.85899H179.879L175.946 3.17989L175.903 3.106H175.817H175.776H175.626V3.256V9.85899H174.468L174.472 0.976004H175.853L179.798 7.66716L179.841 7.74099H179.927H179.952H180.102V7.59099Z",
    length: 53,
  },
  {
    d: "M183.233 0.976004H189.226V2.06599H184.613H184.463V2.21599V4.61601V4.76601H184.613H188.87V5.779H184.613H184.463V5.929V8.61501V8.76501H184.613H189.311V9.85499H183.234L183.233 0.976004Z",
    length: 49,
  },
  {
    d: "M192.189 8.76501H196.884V9.85499H190.809V0.976004H196.798V2.06599H192.189H192.039V2.21599V4.61601V4.76601H192.189H196.445V5.779H192.189H192.039V5.929V8.61501V8.76501H192.189Z",
    length: 49,
  },
  {
    d: "M198.383 0.976004H202.408C203.34 0.976004 204.022 1.20222 204.469 1.59595C204.913 1.9871 205.147 2.56215 205.147 3.30699V3.31484L205.148 3.32264C205.196 3.78113 205.09 4.24257 204.846 4.63376C204.602 5.02495 204.234 5.32353 203.801 5.48216L203.703 5.51822V5.623V5.64799V5.77756L203.831 5.7964C204.067 5.83101 204.356 5.94067 204.587 6.20266C204.816 6.46268 205.002 6.88895 205.003 7.58421C204.932 8.36461 205.067 9.14854 205.391 9.85899H204.089C203.933 9.48446 203.87 9.07729 203.906 8.67232L203.907 8.67237V8.65901C203.907 7.81632 203.827 7.16702 203.542 6.72897C203.245 6.27208 202.75 6.077 202.02 6.077H199.766H199.616V6.22701V9.85899H198.387L198.383 0.976004ZM199.612 5.00478L199.612 5.15499H199.762H202.266C202.485 5.18316 202.708 5.16166 202.918 5.09199C203.131 5.02123 203.325 4.90267 203.486 4.74539C203.646 4.5881 203.768 4.39626 203.843 4.18452C203.917 3.97555 203.942 3.75265 203.918 3.53242C203.916 2.91932 203.725 2.51943 203.397 2.28066C203.078 2.04815 202.659 1.99101 202.253 1.99101H199.766H199.616L199.616 2.1408L199.612 5.00478Z",
    length: 53,
  },
]
