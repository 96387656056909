import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

import { SubHeadingPaths } from "./index"
import { Icon, IconProps } from "@chakra-ui/react"

const fill = keyframes`
  from { fill: transparent; }
  to { fill: #352d87; }
`

const line = keyframes`
  to { stroke-dashoffset: 0; }
`

const Path = styled.path<{ length: number; delay: number }>`
  stroke-dasharray: ${(props) => props.length};
  stroke-dashoffset: ${(props) => props.length};
  animation: ${line} 2s ease forwards;
  animation-delay: ${(props) => `${props.delay}s`};
`

interface LogoSubTitleProps extends IconProps {
  animate?: boolean
}

export const LogoSubTitle = ({ animate = false, ...rest }: LogoSubTitleProps) => {
  return (
    <Icon
      width="206"
      height="11"
      viewBox="0 0 206 11"
      color={animate ? "#352d87" : "white"}
      fill={animate ? "none" : "white"}
      willChange="auto"
      animation={animate ? `${fill} 0.5s ease forwards 4s` : "none"}
      {...rest}
    >
      {SubHeadingPaths.map((path, index) => {
        return (
          <Path
            key={index}
            stroke={animate ? "currentColor" : "inherit"}
            strokeWidth="0.3"
            d={path.d}
            length={path.length}
            delay={index * 0.1 + 0.1}
          />
        )
      })}
    </Icon>
  )
}
